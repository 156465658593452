header {
  background-color: rgb(130, 156, 179);
  width: 100%;
  align-items: center;
  display: flex;
  font-size: 1rem;
  justify-content: space-between;
}

header h1 {
  font-size: 20px;
  color: #ffffff;
  text-align: start;
  padding: 0.5%;
  text-shadow: #000000 0.03em 0.03em;
  font-family: "Roboto", sans-serif;
}

header nav {
  display: flex;
  width: 20%;
  padding-right: 1%;
  position: relative;
}

li {
  list-style: none;
  padding: 0.5rem;
  font-family: "Roboto", sans-serif;
}

li a {
  color: white;
}

li :hover {
  color: rgb(58, 83, 226);
  transition: 0.08s;
}
